import React, { Component } from 'react'
import { DateRangePicker } from 'react-date-range';
import "rc-time-picker/assets/index.css";
import * as locales from 'react-date-range/dist/locale';
import TimePicker from 'rc-time-picker';
import FormatHelper from '../../libraries/format-helper';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const str = 'HH:mm:ss'
class CustomDate extends Component {

    constructor(props){
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            startHour:'00:00:00',
            endHour:'23:59:00'
        }
    }
    handleOnChange(value){
        this.setState({
            startDate:value.startDate,
            endDate:value.endDate,
        })
    }

    handleChange(e, prop) {
        try{
            this.setState({
                [prop]: e.format(str)
            })
        }catch(e){
            console.log(e)
        }
    };

    handleDateFilter(){
        console.log('HOraStart:=>',this.state.startHour)
        console.log('HOraStart:=>',this.state.endHour)
        var startDate = FormatHelper.dateService(this.state.startDate+"")+" "+this.state.startHour
        var endDate = FormatHelper.dateService(this.state.endDate+"")+" "+this.state.endHour 
        this.props.handleDates(startDate,endDate)
    }

    render(){
        const {t}=this.props;
        return (
            <div>
                <div className="right-dialog">
                    <div className="row col-md-12" >
                        <div className="col-md-4"/>
                        <div className="row col-md-8">
                            <p className=" col-md-4">{t('calendar.schedule')} </p>
                            <TimePicker
                                style={{width:'100px',marginLeft:'-30px'}}
                                defaultValue={moment()}
                                onChange={(e)=>this.handleChange(e,"startHour")} />
                            <p  className="col-md-1">{t('calendar.to')}</p>
                            <TimePicker
                                style={{width:'100px'}}
                                defaultValue={moment()}
                                onChange={e=>this.handleChange(e,"endHour")} />
                        </div>
                    </div>
                    <div className="row col-md-12">
                        <div className="col-md-4"/>
                        <p className="text-small-black col-md-4">{t('calendar.start')}</p>
                        <p className="text-small-black col-md-4">{t('calendar.ends')}</p>
                    </div>
                    <div className="row col-md-12" >
                        <DateRangePicker
                            style={{width:"220px"}}
                            ranges={[this.state]}
                            months={2}
                            onChange={item => this.handleOnChange(item.selection)}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            direction="horizontal"
                            preventSnapRefocus={true}
                            calendarFocus="backwards"
                            locale={locales[t('calendar.locale')]}
                        />
                        
                    </div>
                    <div className="container">
                    <button className="btn btn-blue-trans" onClick={this.props.handleCancel}>{t('calendar.cancel')}</button>
                    <button className="btn btn-blue" onClick={()=>this.handleDateFilter()}>{t('calendar.apply')}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation("global")(CustomDate);