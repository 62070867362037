import { FormControlLabel, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { withTranslation } from 'react-i18next';

class Filters extends Component {
    constructor(props){
        super(props);
        this.state={
            status: this.props.status,  //'car lane,bus lane,',
            type:this.props.type,  //'car,metrobus,motorcycle,police,ambulance,',
            day:this.props.day,  //'day,night,',
            checkCar:this.props.checkCar,
            checkMetro:this.props.checkMetro,
            checkMoto:this.props.checkMoto,
            checkPoli:this.props.checkPoli,
            checkAmb:this.props.checkAmb,
            checkCarL:this.props.checkCarL,
            checkBusL:this.props.checkBusL,
            checkDay:this.props.checkDay,
            checkNigth:this.props.checkNigth,
        }
    }

    handleTypeChange(text,event,prop){
        var textType = this.state.type
        if(event.target.checked){
            textType = textType.concat(text)
        }else{
            textType = textType.replace(text,'')
        }
        this.setState({
            type:textType,
            [prop]:event.target.checked,
        },()=>{
            //console.log("TIPO:",this.state.type)
            this.props.handleCheck(prop,event.target.checked);
            this.props.handleFilter(this.state.type,this.state.status,this.state.day)
        })
    }
    handleStatus(text,event,prop){
        var textStatus = this.state.status
        if(event.target.checked){
            textStatus=textStatus.concat(text)
        }else{
            textStatus=textStatus.replace(text,'')
        }
        this.setState({
            status:textStatus,
            [prop]:event.target.checked,
        },() => {
            this.props.handleCheck(prop,event.target.checked);
            this.props.handleFilter(this.state.type,this.state.status,this.state.day)
        })
    }
    handleDay(text,event,prop){
        var textDay = this.state.day
        if(event.target.checked){
            textDay=textDay.concat(text)
        }else{
            textDay=textDay.replace(text,'')
        }
        this.setState({
            day:textDay,
            [prop]:event.target.checked,
        },()=>{
            this.props.handleCheck(prop,event.target.checked);
            console.log("DAYS",this.state.day)
            this.props.handleFilter(this.state.type,this.state.status,this.state.day)
        })
    }

    handleClearFilter(){
        this.props.handleClear()
        this.props.handleClearAll()
        this.setState({
            checkCar:false,
            checkMetro:false,
            checkMoto:false,
            checkPoli:false,
            checkAmb:false,
            checkCarL:false,
            checkBusL:false,
            checkDay:false,
            checkNigth:false,
            status:'',
            type:'',
            day:'',
        })    
        this.props.handleCheck("checkCar",false);
        this.props.handleCheck("checkMetro",false);
        this.props.handleCheck("checkMoto",false);
        this.props.handleCheck("checkPoli",false);
        this.props.handleCheck("checkAmb",false);
        this.props.handleCheck("checkCarL",false);
        this.props.handleCheck("checkBusL",false);
        this.props.handleCheck("checkDay",false);
        this.props.handleCheck("checkNigth",false);
        //this.props.handleCloseFilter()
    }

    handleHideFilter(){
        //this.props.handleClear()
        this.props.handleCloseFilter(false)
    }

    render(){
        const {t} = this.props
        return (
            <div className="row col-md-12" style={{marginLeft:'30px'}}>
                
                <div className="col-md-5">
                   <p className="text-black2">{t('filter.vehicleType')}</p>
                   <FormControlLabel control={<Checkbox checked={this.state.checkCar} onChange={(e)=>this.handleTypeChange('car,',e,'checkCar')} />} 
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18}}} 
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.car')}</Typography>} />
                   <FormControlLabel control={<Checkbox checked={this.state.checkMetro} onChange={(e)=>this.handleTypeChange('metrobus,',e,'checkMetro')} />} 
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.metrobus')}</Typography>} />
                   <FormControlLabel control={<Checkbox checked={this.state.checkMoto} onChange={(e)=>this.handleTypeChange('motorcycle,',e,'checkMoto')} />} 
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.moto')}</Typography>} />
                   <FormControlLabel control={<Checkbox checked={this.state.checkPoli} onChange={(e)=>this.handleTypeChange('police,',e,'checkPoli')} />} 
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.police')}</Typography>}  />
                   <FormControlLabel control={<Checkbox checked={this.state.checkAmb} onChange={(e)=>this.handleTypeChange('ambulance,',e,'checkAmb')} />} 
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.ambulance')}</Typography>}  />
                </div>
                <div className="col-md-3">
                   <p className="text-black2">{t('filter.status')}</p>
                   <FormControlLabel control={<Checkbox checked={this.state.checkCarL} onChange={(e)=>this.handleStatus('car lane,',e,'checkCarL')}/>} 
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.carLane')}</Typography>}  />
                   <FormControlLabel control={<Checkbox checked={this.state.checkBusL} onChange={(e)=>this.handleStatus('bus lane,',e,'checkBusL')} />}
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.busLane')}</Typography>}  />
                </div>
                <div className="col-md-2">
                    <p className="text-black2">{t('filter.capturePeriod')}</p>
                    <FormControlLabel control={<Checkbox checked={this.state.checkDay} onChange={(e)=>this.handleDay('day,',e,'checkDay')} />} 
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                    label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.day')}</Typography>}  />
                   <FormControlLabel control={<Checkbox  checked={this.state.checkNigth} onChange={(e)=>this.handleDay('night,',e,'checkNigth')} />} 
                                sx={{'& .MuiSvgIcon-root': {fontSize: 18,}}}
                                label={<Typography style={{fontSize:'15px',color:'#000000'}}>{t('filter.night')}</Typography>}  />
                </div>
                <div className="col-md-1" style={{paddingLeft:'40px',marginTop:'15px'}}>
                    <p className="text-blue"  onClick={()=>this.handleClearFilter()}>{t('filter.clearFilter')}</p>
                </div>
                <div className="col-md-1" style={{paddingLeft:'40px',marginTop:'15px'}}>
                    <p className="text-blue"  onClick={()=>this.handleHideFilter()}>{t('filter.hideFilter')}</p>
                </div>
            </div>
        )
    }
}
export default withTranslation("global")(Filters);
