import axios from 'axios';

class Api{

    static get(route) {

        return this.xhr(route,);

    }

    static xhr(route) {

        let url = null;

        const host =  "http://3.94.121.173"
        
        url = `${host}${route}`;

        console.log("URL=>",url)

        return axios(url,{
            auth:{
                username:'N30l0gyNYc@',
                password:'NyC1tYN30'
            },
            headers:{
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Method': '*',
                'Access-Control-Allow-Credentials':'true',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': '*',
            },
        }).then(resp=>{
            console.log("RESPONSE:",resp.data)
            return resp.data
        }).catch((e) => {
            console.log("error=>",e);
        });
    }
}
export default Api;
