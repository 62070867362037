import moment from 'moment'

class FormatHelper {

    static date(data) {
        return moment(data).format('DD MMM YYYY')
    }

    static time(data) {
        return moment(data).add(5,'h').format('HH:mm:ss')
        //return moment(data).format('hh:mm:ss')
    }

    static dateTime(data) {
        return moment(data).add(5,'h').format('DD MMM YYYY  HH:mm:ss')
        //return moment(data).format('DD MMM YYYY  HH:mm:ss')
    }

    static dateService(data){
        return moment(data).format('YYYY-MM-DD')
    }

    static dateTimeService(data){
        return moment(data).format('YYYY-MM-DD HH:mm:ss')
    }

    static amount(data) {
        return data.toFixed(2);
    }

    static urlQuery(params) {
        let url = '?'
        Object.keys(params).map(function (index) {
            url = url + "&" + index + "=" + params[index]
            return url
        })
        return url
    }
}


export default FormatHelper;