import { TextField } from '@material-ui/core';
import React, {Component} from 'react'
import { Card } from 'reactstrap';
import metro from '../../images/metrobus.png';
import logo from '../../images/neology-logo.png';
import Api from '../../libraries/api';
import FormatHelper from '../../libraries/format-helper';
import {toast,ToastContainer} from "react-toastify";
import Cookies from 'universal-cookie'
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation} from "react-i18next"

const cookies = new Cookies();

class Login extends Component {
    
    constructor(props){
        super(props)
        this.state={
            user:'',
            pass:'',
            loading:false,
            incorrect:false,
            error:false,
            language:'Español',
        }
        this.handleLogIn = this.handleLogIn.bind(this);
        this.removeValues();
        cookies.set('language','es',{path:"/"})
    }
    removeValues(){
        cookies.remove('id',{path:"/"})
        cookies.remove('user',{path:"/"})
        cookies.remove('email',{path:"/"})
        cookies.remove('status',{path:"/"})
    }

    handleLogIn(){
        this.setState({loading:true})
        let params = {
            user:this.state.user,
            password:this.state.pass
        };
        console.log("User",this.state.user)
        console.log("Pass",this.state.pass)
        let urlParams = FormatHelper.urlQuery(params)
        let url = "/login"+urlParams
        Api.get(url,params).then(resp =>{
            console.log("Response=>",resp)
            if(resp!=null){
                window.location.href="./dashboard";
                cookies.set('id',resp.id,{path:"/"})
                cookies.set('user',resp.user,{path:"/"})
                cookies.set('email',resp.email,{path:"/"})
                cookies.set('status',resp.status,{path:"/"})
            }else{
                this.customToast()
            }
            this.setState({
                loading:false  
            })
        }).catch(err=>{
            console.log("Error=>",err)
        })      
    }

    customToast(){
        toast.info(
            <div className="row col-md-12" > 
                <h6 style={{margin:'auto',textAlign:'center'}}>
                    Nombre de usuario o contraseña incorrectos
                </h6>
            </div>, {
            icon:false,    
            position: toast.POSITION.TOP_CENTER,
            autoClose:5000,
            closeButton:false,
            hideProgressBar:true,
            className:'toast-error'
         } );   
         this.setState({error:true})
    }

    handleChange(e, prop) {
        console.log("VALUE",e.target.value)
        this.setState({
            [prop]: e.target.value,
            error:false
        },()=>{
            if(prop==="language"){
                var leng = (this.state.language==="Español") ? "es":"en"
                cookies.set('language',leng,{path:"/"})
            }
        })
    
    };

    render(){
        const  {t,i18n} = this.props
        return (
            <main className="login">
                <ToastContainer />
                <div>
                <Card style={{ width:'500px',paddingLeft:'70px',paddingRight:'70px',paddingTop:'30px',paddingBottom:'30px', borderRadius: '10px' }}>
                <img alt="metro" className="icon-metro" src={metro} style={{height:'40px',width:'60px',margin: 'auto'}}/>
                <p className="text-subTitle2" style={{margin: 'auto',marginTop:'20px'}}>{t('login.welcome')}</p>
                    <div style={{marginTop:'30px'}}>
                        <p className="text-black2">{t("login.user")}</p>
                        <input
                            className={this.state.error?"text-edit-error":"text-edit"} 
                            id='user'
                            name="user"
                            placeholder={t('login.username')}
                            onChange={(e) => this.handleChange(e, 'user')}
                            minLength={4}
                            maxLength={50}
                            value={this.state.user}
                        />
                    </div>
                    <div style={{marginTop:'30px',color:'#000000'}}>
                        <p className="text-black2">{t("login.pass")}</p>
                        <input
                            className={this.state.error?"text-edit-error":"text-edit"} 
                            type= 'password'
                            id="adornment-password"
                            name="password"
                            placeholder={t("login.pass")}
                            onChange={(e) => this.handleChange(e, 'pass')}
                            value={this.state.pass}
                        />
                    </div>
                    <button onClick={this.handleLogIn} className="btn-blue" style={{marginTop:'30px'}}>{t('login.enter')} {
                    this.state.loading ?<i className="fa fa-spinner fa-spin"> </i> : ('')}</button>
                </Card>                    
                <div className='row col-md-12' style={{marginTop:"70px",alignItems:'center'}}>
                    <div className='col-md-2' >
                        <TextField
                            style={{marginTop:'-15px',marginLeft:'20px',width:'150%'}}
                            id="select"
                            select
                            value={this.state.language}
                            onChange={e => this.handleChange(e,'language')}
                            InputProps={{ disableUnderline: true}}
                            variant="standard"
                            >
                            <MenuItem value="Español"  onClick={()=>i18n.changeLanguage("es")}>{t('login.spanish')}</MenuItem>
                            <MenuItem value="Ingles" onClick={()=>i18n.changeLanguage("en")}>{t('login.english')}</MenuItem>
                            
                        </TextField>
                    </div>
                    <p className='col-md-5 text-gray' style={{fontSize:'14px',textAlign:'end'}}>{t('login.developed')}</p>
                    <img alt ="logo" className="col-md-1 " src={logo} style={{height:'40px',width:'100px',marginTop:'-45px',marginLeft:'-10px'}}/>
                </div>
             </div>           
        </main>
            
        )
    }
}
export default withTranslation('global') (Login);