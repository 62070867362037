import React from 'react'
import { Component } from 'react'
import {  Table, TableCell, TableRow } from '@material-ui/core';
import { TableHead, TableBody } from '@material-ui/core';
import HeaderTab from './header';
import Api from '../../libraries/api';
import DetailVehicle from './detail';
import Card from '@mui/material/Card';
import FormatHelper from '../../libraries/format-helper';
import TextDoble  from '../presentational/text-doble';
import GeneralInfo from '../presentational/general-info';
import Filters from './filters';
import CircularProgress from "@material-ui/core/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomDate from './calendar';
import Cookies from 'universal-cookie';
import { withTranslation } from 'react-i18next';
import Traslate from '../../libraries/traslate-text'
import { CSVLink } from 'react-csv';

const cookies = new Cookies();
const urlImage = "https://metrobus-back.s3.amazonaws.com/";
const style = {
    tabHead:{
        borderBottom:'0px',
        fontWeight:'bolder',
        color:'#656565',
        paddingTop:'0px',
        paddingBottom:'0px',
        borderRight:"1px solid #d2d2d2"
    }
  };

class Dashboard extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentPage:0,
            pageFilter:0,
            size:20,
            showDetail:false,
            showFilters:false,
            filters:false,
            showCalendar:false,
            loading:true,
            loadingUpdate:false,
            vehicle:{},
            vehicleList:[],
            startDate: 'Últimos 7 días',
            endDate:'',
            status:'car lane, bus lane,',
            type:'car, metrobus, motorcycle, police, ambulance,',
            day:'day, night,',
            init : true,
            dataCSV:[],
            downloading:false,
            nameCSV:'',
            showCSV:false,
            checkCar:true,
            checkMetro:true,
            checkMoto:true,
            checkPoli:true,
            checkAmb:true,
            checkCarL:true,
            checkBusL:true,
            checkDay:true,
            checkNigth:true,
        }
        this.csvLink = React.createRef();
        this.getReadsList = this.getReadsList.bind(this);
        this.getReadsList();
        this.handleShowFilters = this.handleShowFilters.bind(this);
        this.handleSetDates = this.handleSetDates.bind(this);
        this.handleSetFilter = this.handleSetFilter.bind(this);
        this.setDates = this.setDates.bind(this);
        this.setDates();
        this.handleClearAll = this.handleClearAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }    

    handleCheck(prop,checked){
        this.setState({
            [prop]:checked,
        })
    }

    componentDidMount(){
        if(!cookies.get('user')){
            window.location.href="./"
        }
    }

    setDates(){
        var currentTime = Date.now();
        var today = new Date(currentTime);
        var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7,0,0,0);
        var endToday = FormatHelper.dateTimeService(today+""); 
        var startToday = FormatHelper.dateTimeService(lastWeek+""); 
        this.setState({
            startDate: startToday,
            endDate: endToday
        });
    }
    
    getReadsList(){
        this.setState({
            filters:false, 
            vehicleList:[],
            currentPage:0
        })
        let currentPage = 0;//this.state.currentPage ;
        let params = {
            page: currentPage,
            size: this.state.size
        };
        let urlParams = FormatHelper.urlQuery(params)
        let url = "/reads/pages"+urlParams
        Api.get(url,params).then(resp =>{
            this.setState({
                vehicleList:resp,
                loading:false    
            })
        }).catch(err=>{
            console.log("Error=>",err)
        })     
    }
    
    getBottomScroll(){
        window.onscroll = function(ev) {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                //if(this.state.loadingUpdate === false){
                    this.setState({loadingUpdate:true})
                //}
            }
        }.bind(this);
    }

    setFetchReads(){
        if( this.state.loading === false){
            if(this.state.filters === false){
                this.updateReads()
            }else{
                this.handleUpdateListFilter()    
            }
        }
    }

    updateReads(){
        let currentPage = this.state.currentPage + 1 ;
        let params = {
            page: currentPage,
            size: this.state.size
        };
        //console.log("request:",params)
        let urlParams = FormatHelper.urlQuery(params)
        let url = "/reads/pages"+urlParams
        Api.get(url,params).then(resp =>{  
            var items = this.state.vehicleList
            var list = items.concat(resp)
            //console.log("resp=>",resp)
            this.setState({
                currentPage:currentPage,
                vehicleList:list,
            })
        }).catch(err=>{
            console.log("Error=>",err)
        })     
    }

    handleClearAll(){
        this.setState({
            type:"",
            status:"",
            day:"",
        })
    }

    handleSetFilter(type,status,day){
        this.setState({
            filters:true,
            type:type,
            status:status,
            day:day,
        })
        if(this.state.startDate === "Últimos 7 días"){
            this.setDates()
        }
        setTimeout(() => {
            this.setState({
                type:type,
                status:status,
                day:day,
                pageFilter:0,
                filters:true,
            },()=>{
                this.handleGetListFilter()
            })
        }, 100)   
    }

    handleGetListFilter(){
        this.setState({
            vehicleList:[],
            loading:true
        })
        let params = {
            dateBefore: this.state.startDate,
            dateAfter: this.state.endDate,
            type: this.state.type,
            description:this.state.status,
            day:this.state.day,
            page : 0,
            size: this.state.size,
        };
       // console.log("request=>",params);
        let urlParams = FormatHelper.urlQuery(params)
        let url = "/read/filters/pages"+urlParams
        Api.get(url,params).then(resp =>{
            this.setState({
                vehicleList:resp,
                loading:false,       
            })
        }).catch(err=>{
            this.setState({loading:false})
            console.log("Error=>",err)
        })   
    }

    handleUpdateListFilter(){  
        let currentPage = this.state.pageFilter + 1 ;
        let params = {
            dateBefore: this.state.startDate,
            dateAfter: this.state.endDate,
            type: this.state.type,
            description:this.state.status,
            day:this.state.day,
            page : currentPage,
            size: this.state.size,
        };
        //console.log("request=>",params);
        let urlParams = FormatHelper.urlQuery(params)
        let url = "/read/filters/pages"+urlParams
        Api.get(url,params).then(resp =>{
            var items = resp
            var list = this.state.vehicleList.concat(items)
            this.setState({
                vehicleList:list,
                pageFilter:currentPage    
            })
        }).catch(err=>{
            console.log("Error=>",err)
        })   
    }

    handleShowDetail(status,vehicleSelect) {
        this.setState({
            showDetail: status,
            vehicle:vehicleSelect
        })
    }

    handleShowFilters(refresh){
        this.setState({
             showFilters: !this.state.showFilters,    
        },()=>{
            if(refresh){
                this.getReadsList()
            }
        })
    }
    handleShowCalendar(){
        this.setState({ showCalendar:!this.state.showCalendar })
    }

    handleSetDates(startDate,endDate){
        this.setState({
            startDate:startDate,
            endDate:endDate
        })
        this.handleShowCalendar()
    }

    setNameCSV(){
        var currentTime = Date.now();
        var date = FormatHelper.dateTimeService(currentTime)
        var name = 'ReporteMetrobus '+date+'.csv'
        this.setState({nameCSV:name})
    }

    handleDownloadCSV (){
        this.setDates();
        this.setNameCSV();
        setTimeout(() => { this.DownloadCSV()}, 200);
    }

    DownloadCSV(){
        this.setState({downloading:true})
        let params = {
            dateBefore: this.state.startDate,
            dateAfter: this.state.endDate,
            type: this.state.type,
            description:this.state.status,
            day:this.state.day,
        };
        let urlParams = FormatHelper.urlQuery(params)
        let url = "/read/filters"+urlParams
        Api.get(url,params).then(resp =>{
            if(resp!=null){
                this.setState({
                    dataCSV:resp,
                    downloading:false
                },()=>{
                    setTimeout(() => { 
                        this.csvLink.current.link.click()
                    })
                })
            }
            this.setState({downloading:false})
        }).catch(err=>{
            this.setState({downloading:false})
            console.log("Error=>",err)
        })   
    }

    render(){
        const  {t,i18n} = this.props
        if(this.state.init){
            i18n.changeLanguage(cookies.get('language'))
            this.setState({init:false})
        }
        return (
            <div style={{backgroundColor:'#F1F1F1'}}>
                {this.state.showCalendar ? <CustomDate handleCancel={()=>this.handleShowCalendar()}
                        handleDates={this.handleSetDates}/> : ('')
                }
                {this.state.showDetail === false ? (''):
                    (<DetailVehicle vehicle={this.state.vehicle} handleClose={() => this.handleShowDetail(false)} />) 
                }
                <HeaderTab />
                <div className="row col-md-12" style={{backgroundColor:'#F1F1F1',top:10,width:'103%',position:'fixed'}}>
                    <h2 className='text-subTitle col-md-5' style={{marginLeft:'50px'}}>
                        {t('header.title')}
                    </h2>
                    <div className="col-md-7" style={{marginLeft:'-100px',marginTop:'70px'}}>
                        <div className="row col-md-12" style={{marginTop:'20px'}}>
                            <div className="row col-md-9" style={{cursor:'pointer',paddingLeft:'200px'}} onClick={()=>this.handleShowCalendar()}>
                                    <p className="text-small col-md-5" style={{padding:'0px',textAlign:'end'}} >{t('header.thinkDate')}</p>
                                    <i className="far fa-calendar col-md-1"></i> 
                                    {this.state.startDate === "Últimos 7 días"? (
                                    <div  className="col-md-6" >
                                        <p className="text-black2 " style={{marginTop:'-2px'}}>
                                            {t('header.lastDays')} 
                                        </p>
                                    </div>
                                    ):(
                                    <div  className="col-md-6">
                                        <p className="text-black2 " style={{marginTop:'-5px',fontSize:'13px'}}> {t('header.from')} {this.state.startDate} </p>
                                        <p className="text-black2 " style={{marginTop:'-20px',fontSize:'13px'}}> {t('header.to')} {this.state.endDate}</p>
                                    </div>
                                    )}
                            </div>
                            <div className="col-md-3" style={{textAlign:'end'}}>
                                <button className="btn-outline" onClick={()=>this.handleShowFilters(false)} style={{textAlign:'center',width:'70%'}}>
                                    <i className="fas fa-filter" ></i> {t('header.filterBy')} 
                                </button>                          
                            </div>
                        </div>                        
                    </div>
                    <div className="row col-md-12" style={{marginBottom:'10px'}}>
                        {this.state.showFilters ? (
                        <div className="row">
                            <div className="col-md-10" >   
                                <Filters handleClear={this.getReadsList} 
                                    handleCloseFilter={this.handleShowFilters} 
                                    handleFilter={this.handleSetFilter}
                                    handleClearAll={this.handleClearAll}
                                    handleCheck={this.handleCheck}
                                    type={this.state.type}
                                    status={this.state.status}
                                    day={this.state.day}
                                    checkCar={this.state.checkCar}
                                    checkMetro={this.state.checkMetro}
                                    checkMoto={this.state.checkMoto}
                                    checkPoli={this.state.checkPoli}
                                    checkAmb={this.state.checkAmb}
                                    checkCarL={this.state.checkCarL}
                                    checkBusL={this.state.checkBusL}
                                    checkDay={this.state.checkDay}
                                    checkNigth={this.state.checkNigth}
                                    />
                            </div>
                            <div  className="col-md-2" style={{marginTop:'40px',textAlign:'end',paddingRight:'40px'}}>
                                <div className="col-md-12">
                                    <button className="btn btn-text-black" 
                                            onClick={()=>this.handleDownloadCSV()}
                                            disabled={this.state.status===''||this.state.day===''||this.state.type===''?true:false}
                                            > &nbsp;
                                            { this.state.downloading ?<i className="fa fa-spinner fa-spin"/> : ('') } &nbsp;
                                            <i class="fas fa-download" style={{marginRight:'3px'}}/>
                                            {t('header.downloadCSV')}
                                    </button>
                                    <CSVLink 
                                        data={this.state.dataCSV}
                                        target="_blank"
                                        filename={this.state.nameCSV}
                                        ref={this.csvLink}
                                    />
                                </div>
                            </div>
                        </div>
                         ):('')
                        } 
                    </div>
                </div>
                {this.state.showFilters ? (<div style={{paddingTop:'10px',marginTop:'60px'}}/>):('')}
                <div style={{paddingTop:'10px',paddingBottom:'20px',marginTop:'0px'}}>
                    <Card style={{ borderRadius: '10px',margin:'160px 40px 0px 40px', paddingTop:'10px',display: 'block'}}>
                        <div className="row col-md-12" style={{marginLeft:'20px',marginTop:'10px'}}>
                            
                        </div>
                        <div className='table-style'>
                            <InfiniteScroll
                                dataLength={this.state.vehicleList.length}
                                next={()=>this.setFetchReads()}
                                hasMore={true}
                                loader={
                                    <Table >
                                        <TableRow className="col-md-12">
                                            <TableCell  align="center" className="py-5">
                                                <CircularProgress className="container-center" color="primary"/>
                                                <h4 className="text-dark-blue">{t('table.loadingRecords')}</h4>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                }
                            >
                                <Table >
                                    <TableHead className="head-theme">
                                        <TableRow  className="MuiTableBody-root">
                                        
                                            <TableCell align="left" style={style.tabHead}>Id</TableCell>
                                            <TableCell align="left" style={style.tabHead}>{t('table.vehicle')}</TableCell>
                                            <TableCell align="left" style={style.tabHead}>{t('table.photoCapture')}</TableCell>
                                            <TableCell align="left" style={style.tabHead}>{t('table.plate')}</TableCell>
                                            <TableCell align="left" style={style.tabHead}>{t('table.imagePlate')}</TableCell>
                                            <TableCell align="left" style={style.tabHead}>{t('table.generalInfo')}</TableCell>
                                            <TableCell align="left" style={style.tabHead}>{t('table.status')}</TableCell>
                                            <TableCell align="left" style={{borderBottom:'0px',fontWeight:'bolder',color:'#656565',paddingTop:'0px',paddingBottom:'0px'}}>
                                                {t('table.dayNight')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.vehicleList.map((vehicle,index) =>(
                                            <TableRow style={{height:'50px'}} hover key={index} onClick={()=> this.handleShowDetail(true,vehicle)}>
                                                <TableCell align="center" style={{height:'50px'}}>{vehicle.id}</TableCell>
                                                <TableCell align="center" style={{height:'50px'}}>
                                                    <TextDoble title={t(Traslate.Text(vehicle.type))} subTitle={vehicle.type_percentage}/>
                                                </TableCell>
                                                <TableCell align="center" style={{height:'50px'}}>
                                                    <img alt="overview" className="img-overview" src={urlImage+vehicle.overview}/>
                                                </TableCell>
                                                <TableCell align="center" style={{height:'50px',fontSize:'20px'}}>{vehicle.plate}</TableCell>
                                                <TableCell align="center" style={{height:'50px'}}>
                                                    <img alt="plate" className="img-plate" src={urlImage+vehicle.patch}/>
                                                </TableCell>
                                                <TableCell align="center" style={{height:'60px'}}>
                                                    <GeneralInfo vehicleInfo={vehicle}/>
                                                </TableCell>
                                                <TableCell align="center" style={{height:'50px'}}>
                                                    <TextDoble title={t(Traslate.Text(vehicle.description))} subTitle={vehicle.detection} status={true}/>
                                                </TableCell>
                                                <TableCell align="center" style={{height:'50px'}}>
                                                    <TextDoble title={t(Traslate.Text(vehicle.day))} subTitle={vehicle.day_percentage}/>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>  
                            </InfiniteScroll>   
                        </div>
                    </Card>
                </div>            
            </div>
        )
    }
}
export default withTranslation('global') (Dashboard);
