import { Component } from "react";
import { withTranslation } from "react-i18next";


class TextDoble extends Component{

    constructor(props){
        super(props)
        this.state={
            title:this.props.title,
            subTitle:this.props.subTitle,
            status:this.props.status
        }
    }
    render(){
        const {t}= this.props
        return(
            <div>
                {this.state.title === (t('filter.busLane')) ? (
                    <h6 className="text-red">{this.state.title}</h6>
                ):( this.props.title === ""?(
                        <h6 className="text-yellow">{this.props.status === true? (t('detail.pending')):""}</h6>
                    ) :(
                        <h6 className="text-black2">{this.state.title}</h6>
                    )
                )}
                <h7 className="text-small">{this.state.subTitle ==='0' || this.state.subTitle === null ?
                    (''): (this.state.subTitle+'%')}
                </h7>      
            </div>
        )
    }
}
export default withTranslation("global")(TextDoble);
