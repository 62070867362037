import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Dashboard from "./components/dashboard"
import Login from "./components/login"

function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route exact path="/dashboard" component={Dashboard} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;