import { Dialog, DialogTitle } from '@material-ui/core'
import { Component } from 'react'
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

export default class ImageDialog extends Component {

    constructor(props){
        super(props)
    }
  render() {
    return (
      <div>
            <Dialog PaperProps={{style: { borderRadius: '10px',width:'70%', height:'86%',padding:'15px'}}} maxWidth="lg" open={true}>
                <DialogTitle style={{marginBottom:'-20px'}}>
                    <div className="row" style={{marginLeft:'0px'}}>    
                        <button className="btn-close" style={{marginTop:'-10px',height:'10px'}} onClick={()=>this.props.handleShowFullImage()}>
                            <CloseIcon/>
                        </button>
                    </div>
                </DialogTitle>
                <MuiDialogContent>
                    <div style={{marginTop:'30px'}}>
                        <img style={{ height: '100%', width: '100%',borderRadius:'10px'}} 
                            src={this.props.overview} alt="overview" /> 
                    </div>
                </MuiDialogContent>
            </Dialog>
      </div>
    )
  }
}
