
import React, {Component } from 'react'
import FormatHelper from '../../libraries/format-helper';

export default class GeneralInfo extends Component {

    constructor(props){
        super(props);
        this.state={
            vehicle: this.props.vehicleInfo,       
            date:'',
            time:'',
            address:''
        }
        setTimeout(() => { this.setDateTime(); }, 200);
    }

    removeTextNull(value){
        return (value === null)? "" : value+", "
    }
    setDateTime(){
        let date = FormatHelper.date(this.state.vehicle.timestamp)
        let time = FormatHelper.time(this.state.vehicle.timestamp)
        var address = this.removeTextNull(this.state.vehicle.address) + this.removeTextNull(this.state.vehicle.locality) +
                    this.removeTextNull(this.state.vehicle.postalcode) + this.removeTextNull(this.state.vehicle.district) +
                    this.removeTextNull(this.state.vehicle.country)
        this.setState({
            date:date,
            time:time,
            address:address
        })
    }

    render(){
        return (
            <div style={{width:'250px',paddingLeft:'10px'}}>
                <div className="row col-md-12">
                    <h6 className="text-black col-md-6" >{this.state.date} </h6>
                    <h7 className="text-black col-md-2" style={{marginLeft:'-15px',marginTop:'16px',fontSize:'13px'}}>{this.state.time}</h7>      
                </div>      
                <p className="text-gray-2" style={{fontSize:'12px',textAlign:'start',marginTop:'0px'}}>{this.state.address}</p>      
            </div>
        )
    }
}
