import React, { Component } from 'react'
import logo from '../../images/neology-logo.png';
import { AppBar, Avatar, Toolbar, Typography } from '@material-ui/core';
import Cookies from 'universal-cookie';
import { withTranslation } from 'react-i18next';

const cookies = new Cookies();

class TabBar extends Component {


    handleLogOut(){
        window.location.href="./";
    }

    render(){
        const  {t} = this.props
        return (
            <AppBar  style={{backgroundColor:'#F1F1F1' }}>
                    <Toolbar className="col-md-12"> 
                    <div className="row col-md-12">
                        <div className='col-md-8' style={{marginTop:'10px'}}>     
                            <img className="icon-logo" src={logo} alt="logo" />
                        </div >
                        <Typography style={{ flex: 1 }}>
                            <div className="row wrapper" style={{flex:1, right:'0px',marginRight:'-50px'}}>
                                <div className="col-md-4"></div>
                                <p className="text-name col-md-5" style={{marginRight:'-20px'}}>{cookies.get('user')} | Master Admin |</p>
                                <p className="text-name col-md-3" style={{textAlign:'start',cursor:'pointer',color:'red'}}
                                    onClick={()=>this.handleLogOut()}>{t('header.signOff')}</p>
                            </div>
                            <h6 className="text-email">{cookies.get('email')}</h6>
                        </Typography>
                        <Avatar style={{ marginTop:'5px'}}></Avatar>

                    </div>
                </Toolbar>
            </AppBar>
        )
    }
}
export default  withTranslation("global") (TabBar);
