import { Dialog } from '@material-ui/core'
import React, { Component } from 'react'
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import GoogleMapReact from 'google-map-react';
import Api from '../../libraries/api';
import FormatHelper from '../../libraries/format-helper';
import { withTranslation } from 'react-i18next';
import Traslate from '../../libraries/traslate-text'
import ImageDialog from './imageDialog';

class DetailVehicle extends Component {
    
    constructor(props){
        super(props)
        this.state={
            vehicleInfo:this.props.vehicle,
            detail:{
                vehicle: "",
                brand: "",
                color: "",
                id: 0,
                other: "",     
            },
            center: {
                lat: this.props.vehicle.latitude,
                lng: this.props.vehicle.longitude
            },
            zoom: 16.7,
            urlImage:"https://metrobus-back.s3.amazonaws.com/",
            date:'',
            fullImage:false,
        }
        this.renderMarkers = this.renderMarkers.bind(this);
        this.getVehicle = this.getVehicle.bind(this);
        this.getVehicle();
        this.handleShowFullImage = this.handleShowFullImage.bind(this);
    }

    renderMarkers(map, maps) {
        return new maps.Marker({
        position: {
            lat:this.state.vehicleInfo.latitude,
            lng:this.state.vehicleInfo.longitude
        },
          map,
          title: ''
        });
      }

    setDateTime(){
        var datetime = FormatHelper.dateTime(this.props.vehicle.timestamp)
        this.setState({
            date:datetime,
        },()=>{
           // console.log("Date=>",this.state.date)
        })
        
    }

    getVehicle(){    
        let url = "/read/mantis/"+this.props.vehicle.id
        Api.get(url,null).then(resp =>{
            //console.log("REPSONSE=>",resp)
            if(resp!= null){
                this.setState({
                    detail:resp
                })
            }
            this.setDateTime();
        }).catch(err=>{
            this.setDateTime();
            console.log("Error=>",err)
        })  
    }

    setAddress(){
        var address = this.state.vehicleInfo.address+", "+this.state.vehicleInfo.locality+", "+
        this.state.vehicleInfo.postalcode+" "+ this.state.vehicleInfo.district+", "+this.state.vehicleInfo.country+".";
        var newAddress = address.replace(/null/g,"") 
        return newAddress;

    }
    setTextPercent(value){
        return (this.state.vehicleInfo.detection === null)?"":"("+this.state.vehicleInfo.detection+"%)"
    }

    handleShowFullImage(){
        this.setState({
            fullImage: !this.state.fullImage,
        })
    }

    render(){
        const {t} = this.props;
        return (
            <div className="col-md-12">          
            <Dialog PaperProps={{style: { borderRadius: '10px',width:'70%',padding:'15px'}}} maxWidth="lg" open={true}>
                    <DialogTitle style={{marginBottom:'-20px'}}>
                        <div className="row" style={{marginLeft:'0px'}}>    
                            <button className="btn-close" style={{marginTop:'-10px',height:'10px'}} onClick={this.props.handleClose}><CloseIcon/></button>
                        </div>
                        <div className="row col-md-12">
                            <p className="col-md-9 text-subTitle2" >{t(Traslate.Text(this.state.vehicleInfo.type))} {this.setTextPercent(this.state.vehicleInfo.type_percentage)}</p>
                            <h6 className="col-md-3 text-end">{this.state.date}</h6>
                        </div>
                    </DialogTitle>
                <MuiDialogContent>
                    <div className="row col-md-12">
                        <div className="col-md-6" style={{backgroundColor:'#f6f6f6',padding:'20px',borderRadius:'8px'}}>
                            <h5 style={{marginBottom:'10px'}}>{t('detail.description')}</h5>
                            <div className="row col-md-12">
                                <h6 className="col-md-3 text-black">{t('detail.plate')}</h6>
                                <div  className=" col-md-8">
                                    <img className="img-plate"
                                        src={this.state.urlImage+this.state.vehicleInfo.patch} alt="logo" /> 
                                    <h5 style={{marginTop:'5px'}}>{this.state.vehicleInfo.plate}</h5>
                                </div>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-3 text-black">{t('detail.dayNight')}</h6>
                                <h6 className="col-md-6 text-gray-2">{t(Traslate.Text(this.state.vehicleInfo.day))}</h6>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-3 text-black">{t('detail.vehicle')}</h6>
                                <h6 className="col-md-6 text-gray-2">{this.state.detail.vehicle}</h6>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-3 text-black">{t('detail.brand')}</h6>
                                <h6 className="col-md-6 text-gray-2">{this.state.detail.brand}</h6>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-3 text-black">Color:</h6>
                                <h6 className="col-md-6 text-gray-2">{t(Traslate.Color(this.state.detail.color))}</h6>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-3 text-black">{t('detail.others')}</h6>
                                <h6 className="col-md-6 text-gray-2">{this.state.detail.other}</h6>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-3 text-black">{t('detail.location')}</h6>
                                <h6 className="col-md-9 text-gray-2">{this.setAddress()}</h6>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-4 text-black">{t('detail.intersection1')}</h6>
                                <h6 className="col-md-8 text-gray-2">{this.state.vehicleInfo.intersection1}.</h6>
                            </div>
                            <div className="row col-md-12">
                                <h6 className="col-md-4 text-black">{t('detail.intersection2')}</h6>
                                <h6 className="col-md-8 text-gray-2">{this.state.vehicleInfo.intersection2}.</h6>
                            </div>

                        </div>
                        <div className="col-md-6" >
                            <div className="col-md-12">
                                {this.state.vehicleInfo.description === 'Bus Lane'?(
                                     <h4 className="text-alert" > 
                                        <i class="fas fa-exclamation-triangle"></i> &nbsp; {t(Traslate.Text(this.state.vehicleInfo.description))} &nbsp;
                                        {this.setTextPercent(this.state.vehicleInfo.detection)}
                                    </h4>
                                ):(this.state.vehicleInfo.description === 'Car Lane'?(
                                        <h4 className="text-accept" > 
                                            {t(Traslate.Text(this.state.vehicleInfo.description))} &nbsp;
                                            {this.setTextPercent(this.state.vehicleInfo.detection)}
                                        </h4>
                                    ):(
                                        <h4 className="text-warning" > {t('detail.pending')} </h4>
                                    )
                                )}
                                <div style={{marginTop:'-40px'}}>
                                    <img className="image-detail" 
                                        src={this.state.urlImage+this.state.vehicleInfo.overview} 
                                        alt="overview" 
                                        onClick={()=>this.handleShowFullImage()}/> 
                                </div>
                            </div>
                            <div style={{ height: '200px', width: '100%', marginTop:'20px', borderRadius:'20px'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key:"AIzaSyBmVpWVL3DDfJu2W9GfCz1dCCtJt1w4RdQ"}}
                                    defaultCenter={this.state.center}
                                    defaultZoom={this.state.zoom}
                                    onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
                                    >
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <div className="container">
                            <button className="btn btn-blue" style={{paddingLeft:'30px',paddingRight:'30px'}}
                                    onClick={ this.props.handleClose}
                            > {t('detail.accept')}</button>
                        </div>
                    </div>
                </MuiDialogContent>
            </Dialog>
            {this.state.fullImage ? (
                <ImageDialog 
                    overview={this.state.urlImage+this.state.vehicleInfo.overview}
                    handleShowFullImage={this.handleShowFullImage}/>
            ):('')
            }
        </div>
        )
    }
}
export default withTranslation("global")(DetailVehicle);
