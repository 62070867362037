class Traslate{

    static Text(text){
        const textLower = text.toLowerCase();
        switch(textLower){
            case "metrobus":
                return "filter.metrobus" ;
            case "car":
                return "filter.car" ;
            case "motorcycle":
                return "filter.moto" ;
            case "police":
                return "filter.police" ;
            case "ambulance":
                return "filter.ambulance" ;
            case "car lane":
                return "filter.carLane" ;
            case "bus lane":
                return "filter.busLane" ;
            case "day":
                return "filter.day" ;
            case "night":
                return "filter.night" ;
            default:
                return text;    
        }
    } 

    static Color(text){
        return "color."+text; 
    }

}
export default Traslate;